import React from "react";
import "./statusIndicator.css";

const StatusIndicator = ({ title, value, trend, timeFrame }) => {
    const arrow = trend === "up" ? "↑" : "↓";
    const arrowClass = trend === "up" ? "trend-up" : "trend-down";

    return (
        <div className="status-indicator">
            <h6 className="status-title">{title}</h6>
            <div className="value">{value}</div>
            <span className="timeframe">{timeFrame}</span>
            <div className={`trend ${arrowClass}`}>{arrow}</div>
        </div>
    );
};

export default StatusIndicator;
