import React from "react";
import "./panel.css";

const InfoPanel = ({
    title,
    secondHeader,
    mainNumber,
    percentage,
    description,
}) => {
    return (
        <div className="info-panel">
            <h6 className="info-title">{title}</h6>
            <div className="numbers">
                <div className="main-number">{mainNumber}</div>
                <hr className="divider" />
                <div className="percentage2">{percentage}</div>
            </div>
            <div className="information">
                <div className="second-header">{secondHeader}</div>
                <div className="description">{description}</div>
            </div>
        </div>
    );
};

export default InfoPanel;
