import React from "react";
import "./percentagePanel.css";

const PercentagePanel = ({ header1, header2, percentage1, percentage2 }) => {
    return (
        <div className="percentage-panel">
            <h6 className="percentage-title">DEPOSIT UTILIZATION</h6>
            <div className="up">
                <div className="percentage">{percentage1}</div>
                <div className="percentage-header">{header1}</div>
            </div>
            <div className="down">
                <div className="percentage">{percentage2}</div>
                <div className="percentage-header">{header2}</div>
            </div>
        </div>
    );
};

export default PercentagePanel;
