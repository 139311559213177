import * as React from "react";
import { LineChart as LineChartMui } from "@mui/x-charts/LineChart";
import "./lineChart.css";

const LineChart = () => {
    const milions = [
        "10M",
        "9.5M",
        "9M",
        "8.5M",
        "8M",
        "7.5M",
        "7M",
        "6.5M",
        "6M",
    ];
    const years = ["2021", "2022", "2023", "2024"];
    const years2 = ["2024", "2023", "2022", "2021"];

    return (
        <div className="all">
            <div className="main-text">
                <h6 className="big-text">Vault Growth</h6>
                <div className="small-text">
                    The line tracks the increasing value of total assets over
                    time.
                </div>
            </div>
            <div className="main-component-line-chart">
                <div className="milions">
                    {milions.map((value) => (
                        <div key={value} className="milions-item">
                            {value}
                        </div>
                    ))}
                </div>
                <div className="linechart">
                    <LineChartMui
                        xAxis={[{ data: years }]}
                        series={[{ data: [10, 6, 5, 0], showMark: false }]}
                        colors={["green"]}
                        height={250}
                        margin={{ top: 30, right: -70, bottom: 0, left: 10 }}
                    />
                    <div className="years">
                        {years2.map((year) => (
                            <div key={year} className="years-item">
                                {year}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LineChart;
