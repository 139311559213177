import React from "react";
import "./button.css";

const Button = ({ label, color, onClick }) => {
    return (
        <button className="dashboard-button" onClick={onClick}>
            <span className={`color-indicator ${color}`}></span>
            {label}
        </button>
    );
};

export default Button;
