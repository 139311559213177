import axios from "axios";

const API_URL = "http://localhost:3001/api";

export const deposit = async (amount) => {
    try {
        const response = await axios.post(`${API_URL}/transactions`, {
            type: "Deposit 137K USD",
            amount: amount,
        });
        return response.data;
    } catch (error) {
        console.error("Error during deposit", error);
        throw error;
    }
};

export const withdraw = async (amount) => {
    try {
        const response = await axios.post(`${API_URL}/transactions`, {
            type: "Withdraw 89K USD",
            amount: amount,
        });
        return response.data;
    } catch (error) {
        console.error("Error during withdrawal", error);
        throw error;
    }
};

export const getTransactions = async () => {
    try {
        const response = await axios.get(`${API_URL}/transactions`);
        return response.data;
    } catch (error) {
        console.error("Error fetching transactions", error);
        throw error;
    }
};
