import React from "react";
import "./barChart.css";

const BarChart = ({ data }) => {
    return (
        <div className="bar-chart-container">
            <div className="chart-header1">
                <h6 className="chart-title1">Deposit Activity</h6>
                <div className="chart-subtitle1">
                    Each bar represents the total tokens deposited on a given
                    date.
                </div>
            </div>
            <div>
                <div className="bars">
                    <div className="bar" style={{ height: "20%" }}></div>
                    <div className="bar" style={{ height: "30%" }}></div>
                    <div className="bar" style={{ height: "50%" }}></div>
                    <div className="bar" style={{ height: "25%" }}></div>
                    <div className="bar" style={{ height: "35%" }}></div>
                    <div className="bar" style={{ height: "25%" }}></div>
                    <div className="bar" style={{ height: "40%" }}></div>
                    <div className="bar" style={{ height: "30%" }}></div>
                    <div className="bar" style={{ height: "20%" }}></div>
                    <div className="bar" style={{ height: "4%" }}></div>
                </div>
                <div className="chart-footer">
                    <span>24/11</span>
                    <span>01/12</span>
                    <span>8/12</span>
                    <span>15/12</span>
                    <span>22/12</span>
                    <span>29/12</span>
                    <span>05/01</span>
                    <span>12/01</span>
                    <span>12/01</span>
                    <span>Today</span>
                </div>
                <div className="years-container">
                    <div className="twentythree">
                        <span className="year">2023</span>
                    </div>
                    <div className="twentyfour">
                        <span className="year">2024</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BarChart;
