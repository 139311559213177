import React from "react";
import "./financialFigure.css";

const FinancialFigure = ({ title, value, trend, timeFrame }) => {
    const arrow = trend === "up" ? "↑" : "↓";
    const arrowClass = trend === "up" ? "trend-up" : "trend-down";

    return (
        <div className="financial-figure">
            <h6 className="financial-title">{title}</h6>
            <div className="value">{value}</div>
            <div className="trend-and-timeframe">
                <span className="timeframe">{timeFrame}</span>
                <span className={`trend ${arrowClass}`}>{arrow}</span>
            </div>
        </div>
    );
};

export default FinancialFigure;
