import React from "react";
import "./header.css";
import logo from "../../logo/logo.png";

function Header() {
    return (
        <header className="dashboard-header">
            <div className="logo-container">
                <img
                    src={logo}
                    className="logo"
                    style={{ width: "100px", height: "auto" }}
                />
                0xMilenov
            </div>
            <div className="header-text">
                <div className="header">WebThree Vault</div>
                <div className="subtitle">The Architects Of WEB3'S FUTURE</div>
            </div>
        </header>
    );
}

export default Header;
