import React from "react";
import "./dataDisplayPanel.css";

const DataDisplayPanel = ({ header, dataHeader, value }) => {
    return (
        <div className="data-display-panel">
            <h6 className="data-title">{header}</h6>
            <div className="data-value">{value}</div>
            <div className="data-header">{dataHeader}</div>
        </div>
    );
};

export default DataDisplayPanel;
