import React, { useState, useEffect } from "react";
import BarChart from "../Chart/BarChart/BarChart";
import LineChart from "../Chart/LineChart/LineChart";
import PercentagePanel from "../PercentagePanel/PercentagePanel";
import DataDisplayPanel from "../DataDisplayPanel/DataDisplayPanel";
import StatusIndicator from "../StatusIndicator/StatusIndicator";
import FinancialFigure from "../FinancialFigure/FinancialFigure";
import InfoPanel from "../Panel/InfoPanel";
import Button from "../Button/Button";
import { ToastContainer, toast } from "react-toastify";
import { deposit, withdraw, getTransactions } from "../../utils/api";

import "react-toastify/dist/ReactToastify.css";
import "./mainContent.css";

function MainContent() {
    const [isLoading, setIsLoading] = useState(true);
    const [totalStaked, setTotalStaked] = useState(15); // 'M' units
    const [totalWithdrawn, setTotalWithdrawn] = useState(4); // 'M' units
    const [annualROI, setAnnualROI] = useState(70); // as a percentage
    const [withdrawalRate, setWithdrawalRate] = useState(30); // as a percentage
    const [currentTVL, setCurrentTVL] = useState(10.5); // 'M' units
    const [recentDeposits, setRecentDeposits] = useState(1.26); // 'M' units
    const [shareValueChange, setShareValueChange] = useState(0.3); // 'M' units

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const transactions = await getTransactions();
                let staked = 0;
                let withdrawn = 0;
                transactions.forEach((transaction) => {
                    if (transaction.type === "Deposit 137K USD") {
                        staked += transaction.amount / 1000000;
                    } else if (transaction.type === "Withdraw 89K USD") {
                        withdrawn += transaction.amount / 1000000;
                    }
                });
                setTotalStaked(staked.toFixed(2));
                setTotalWithdrawn(withdrawn.toFixed(2));
                setCurrentTVL((staked - withdrawn).toFixed(2));
                setRecentDeposits(staked.toFixed(2));
            } catch (error) {
                console.error("Failed to fetch transactions", error);
                resetToDefaultValues();
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const resetToDefaultValues = () => {
        setTotalStaked(15);
        setTotalWithdrawn(4);
        setAnnualROI(70);
        setWithdrawalRate(30);
        setCurrentTVL(10.5);
        setRecentDeposits(1.26);
        setShareValueChange(0.3);
    };

    const handleButtonClick = async (action) => {
        let amount;
        if (action === "Deposit 137K USD") {
            // Fixed deposit amount
            amount = 137000;
        } else if (action === "Withdraw 89K USD") {
            // Fixed withdrawal amount
            amount = 89000;
        }

        const changeAmount = (amount / 1000000).toFixed(2); // Convert to 'M' units

        try {
            await (action === "Deposit 137K USD"
                ? deposit(amount)
                : withdraw(amount));
            toast.success(`${action} successful!`);
        } catch (error) {
            toast.warn(`${action} offline`);
            console.error(error);
        }

        // Update state regardless of server response
        if (action === "Deposit 137K USD") {
            setTotalStaked((prev) =>
                (parseFloat(prev) + parseFloat(changeAmount)).toFixed(2)
            );
            setAnnualROI((prev) => (parseFloat(prev) + 1).toFixed(2));
            setShareValueChange((prev) => (parseFloat(prev) + 0.05).toFixed(2));
        } else {
            setTotalWithdrawn((prev) =>
                (parseFloat(prev) + parseFloat(changeAmount)).toFixed(2)
            );
            setWithdrawalRate((prev) => (parseFloat(prev) + 1).toFixed(2));
            setShareValueChange((prev) => (parseFloat(prev) - 0.05).toFixed(2));
        }

        setCurrentTVL((prev) =>
            (action === "Deposit 137K USD"
                ? parseFloat(prev) + parseFloat(changeAmount)
                : parseFloat(prev) - parseFloat(changeAmount)
            ).toFixed(2)
        );
        setRecentDeposits((prev) =>
            (action === "Deposit 137K USD"
                ? parseFloat(prev) + parseFloat(changeAmount)
                : parseFloat(prev) - parseFloat(changeAmount)
            ).toFixed(2)
        );
    };

    return (
        <>
            <ToastContainer />
            {isLoading ? (
                <div className="loader">Loading...</div>
            ) : (
                <div className="main-content">
                    <div className="button-container">
                        <Button
                            label="Deposit 137K USD"
                            color="green"
                            onClick={() =>
                                handleButtonClick("Deposit 137K USD")
                            }
                        />
                    </div>
                    <div className="button-container">
                        <Button
                            label="Withdraw 89K USD"
                            color="red"
                            onClick={() =>
                                handleButtonClick("Withdraw 89K USD")
                            }
                        />
                    </div>
                    <div className="bar-chart">
                        <BarChart />
                    </div>
                    <div className="line-chart">
                        <LineChart />
                    </div>
                    <div className="panel-container">
                        <PercentagePanel
                            header1="Annual ROI"
                            header2="Withdrawal Rate"
                            percentage1={`${annualROI}%`}
                            percentage2={`${withdrawalRate}%`}
                        />
                        <DataDisplayPanel
                            header="TOTAL STAKED"
                            value={`$ ${totalStaked} Million`}
                            dataHeader="ACTIVE DEPOSITS"
                        />
                        <DataDisplayPanel
                            header="WITHDRAWN"
                            value={`$ ${totalWithdrawn} Million`}
                            dataHeader="TOTAL WITHDRAWN"
                        />
                    </div>
                    <div className="info-container">
                        <InfoPanel
                            title="EARNINGS ACCUMULATED"
                            mainNumber={`$ ${currentTVL}`}
                            percentage="5.47%"
                            secondHeader="Growth Rate"
                            description="Yearly Increase"
                        />
                        <InfoPanel
                            title="LIQUIDITY OVERVIEW"
                            mainNumber={`$ ${recentDeposits}`}
                            percentage="11.71%"
                            secondHeader="Withdrawal Rate"
                            description="Monthly Trend"
                        />
                    </div>

                    <div className="main-status">
                        <StatusIndicator
                            title="CURRENT TVL"
                            value={`$ ${currentTVL}`}
                            trend="down"
                            timeFrame="-0.06M In 24h"
                        />
                        <FinancialFigure
                            title="RECENT DEPOSITS"
                            value={`$ ${recentDeposits}`}
                            trend="up"
                            timeFrame="+ 0.15M In 7D"
                        />
                        <FinancialFigure
                            title="SHARE VALUE"
                            value={`$ ${shareValueChange}`}
                            trend="down"
                            timeFrame="- 0.010 In 1M"
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default MainContent;
